import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./styles/index.css";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

console.log("Hello there, sneaky inspector!");

window.addEventListener("resize", () => {
  vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

ReactDOM.render(<App />, document.getElementById("root"));
