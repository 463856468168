import React, { useState, Suspense } from "react";

import "./App.scss";

import loading from "../assets/loading.svg";

const Header = React.lazy(() => import("./layout/Header"));
const Footer = React.lazy(() => import("./layout/Footer"));
const Menu = React.lazy(() => import("./layout/Menu"));

const Home = React.lazy(() => import("./content/Home"));
const About = React.lazy(() => import("./content/About"));
const Skills = React.lazy(() => import("./content/Skills"));
const Work = React.lazy(() => import("./content/Work"));
const School = React.lazy(() => import("./content/School"));
const Contacts = React.lazy(() => import("./content/Contacts"));

const App = () => {
  const [menu, setMenu] = useState(false);

  return (
    <Suspense
      fallback={
        <div className="LazyPlaceholder">
          <div>
            <p>Loading...</p>
            <img src={loading} alt="Loading..." />
          </div>
        </div>
      }
    >
      <div className="App">
        <Header
          menuFunc={() => {
            setMenu(!menu);
          }}
        />

        <main>
          <Home />
          <About />
          <Skills />
          <Work />
          <School />
          <Contacts />
        </main>

        <Footer />

        <Menu
          show={menu}
          menuFunc={() => {
            setMenu(!menu);
          }}
        />
      </div>
    </Suspense>
  );
};

export default App;
